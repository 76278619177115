import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit';
import authReducer from '@/modules/auth/store/authSlice';
import { authApi } from '@/modules/auth/api/authApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import loadingReducer from './loadingSlice';
import { ENV } from '@/config/env';
import { usersApi } from '@/modules/users/api/usersApi';
import { accountsApi } from '@/modules/accounts/api/accountsApi';
import { projectsApi } from '@/modules/accounts/api/projectsApi';
import { integrationsApi } from '@/modules/accounts/api/integrationsApi';
import { promptsApi } from '@/modules/accounts/api/promptsApi';
import { triggersApi } from '@/modules/accounts/api/triggersApi';
import { calltouchAccountsSitesApi } from '@/modules/calltouch/api/calltouchAccountsSitesApi';
import { userAccountPermissionApi } from '@/modules/userAccountPermission/api/userAccountPermissionApi';
import { globalVariableApi } from '@/modules/globalVariable/api/globalVariableApi';

// Собираю все редьюсеры
const rootReducer = combineReducers({
	auth: authReducer,
	loading: loadingReducer,
	// Редьюсеры RTK Query для API
	[authApi.reducerPath]: authApi.reducer,
	[usersApi.reducerPath]: usersApi.reducer,
	[accountsApi.reducerPath]: accountsApi.reducer,
	[projectsApi.reducerPath]: projectsApi.reducer,
	[integrationsApi.reducerPath]: integrationsApi.reducer,
	[promptsApi.reducerPath]: promptsApi.reducer,
	[triggersApi.reducerPath]: triggersApi.reducer,
	[calltouchAccountsSitesApi.reducerPath]: calltouchAccountsSitesApi.reducer,
	[userAccountPermissionApi.reducerPath]: userAccountPermissionApi.reducer,
	[globalVariableApi.reducerPath]: globalVariableApi.reducer,
});

const middleware: Middleware[] = [
	authApi.middleware,
	usersApi.middleware,
	accountsApi.middleware,
	projectsApi.middleware,
	integrationsApi.middleware,
	promptsApi.middleware,
	triggersApi.middleware,
	calltouchAccountsSitesApi.middleware,
	userAccountPermissionApi.middleware,
	globalVariableApi.middleware,
];

// Настраиваю store
const store = configureStore({
	reducer: rootReducer,
	// Подключаю middleware для работы с API
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...middleware),
	// Включаю DevTools только для разработки
	devTools: !ENV.IS_PRODUCTION,
});

// Автоматический рефетч данных при старте приложения
setupListeners(store.dispatch);

// Типы для состояния и dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
