// Определение маршрутов приложения с использованием Enum
export enum RouteKeys {
	LOGIN = 'LOGIN',
	RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
	RESET_PASSWORD = 'RESET_PASSWORD',
	WELCOME = 'WELCOME',
	USERS = 'USERS',
	USER_ACCOUNT_PERMISSIONS = 'USER_ACCOUNT_PERMISSIONS',
	ACCOUNTS = 'ACCOUNTS',
	PROJECTS = 'PROJECTS',
	INTEGRATIONS = 'INTEGRATIONS',
	PROMPTS = 'PROMPTS',
	TRIGGERS = 'TRIGGERS',
	CALLTOUCH = 'CALLTOUCH',
	NOT_FOUND = 'NOT_FOUND',
	GLOBAL_VARIABLES = 'GLOBAL_VARIABLES',
}

export const ROUTES: Record<RouteKeys, string> = {
	[RouteKeys.LOGIN]: '/login',
	[RouteKeys.RESET_PASSWORD_REQUEST]: '/password-reset-request',
	[RouteKeys.RESET_PASSWORD]: '/reset-password',
	[RouteKeys.WELCOME]: '/welcome',
	[RouteKeys.USERS]: '/users',
	[RouteKeys.USER_ACCOUNT_PERMISSIONS]: '/user-permissions',
	[RouteKeys.ACCOUNTS]: '/accounts',
	[RouteKeys.PROJECTS]: '/accounts/projects',
	[RouteKeys.INTEGRATIONS]: '/accounts/integrations',
	[RouteKeys.PROMPTS]: '/accounts/prompts',
	[RouteKeys.TRIGGERS]: '/accounts/triggers',
	[RouteKeys.CALLTOUCH]: '/calltouch',
	[RouteKeys.GLOBAL_VARIABLES]: '/global-variables',
	[RouteKeys.NOT_FOUND]: '*',
} as const;

// Заголовки меню приложения
export const MENU_TITLES: Record<RouteKeys, string> = {
	[RouteKeys.LOGIN]: '',
	[RouteKeys.RESET_PASSWORD_REQUEST]: '',
	[RouteKeys.RESET_PASSWORD]: '',
	[RouteKeys.WELCOME]: 'Главная',
	[RouteKeys.USERS]: 'Список пользователей',
	[RouteKeys.USER_ACCOUNT_PERMISSIONS]: 'Доступ к аккаунтам',
	[RouteKeys.ACCOUNTS]: 'Аккаунты',
	[RouteKeys.PROJECTS]: 'Проекты',
	[RouteKeys.INTEGRATIONS]: 'Интеграции',
	[RouteKeys.PROMPTS]: 'Промпты',
	[RouteKeys.TRIGGERS]: 'Триггеры',
	[RouteKeys.CALLTOUCH]: 'Связи аккаунтов и сайтов',
	[RouteKeys.GLOBAL_VARIABLES]: 'Переменные',
	[RouteKeys.NOT_FOUND]: '',
} as const;
