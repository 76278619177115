import { baseQueryWithReauth } from '@/shared/utils/apiBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { CalltouchAccountSite, CalltouchAccountSiteFilters } from '@/modules/calltouch/types';
import { API_ENDPOINTS } from '@/shared/constants/apiEndpoints';
import { buildQueryParams } from '@/shared/utils/buildQueryParams';
import selectFields from '@/shared/utils/selectFields';
import { Filters } from '@/modules/accounts/types';
import processField from '@/shared/utils/processField';

// API для работы с CalltouchAccountsSites
export const calltouchAccountsSitesApi = createApi({
	reducerPath: 'calltouchAccountsSitesApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['CalltouchAccountsSites'],
	endpoints: (builder) => ({
		// Получение метаинформации для страницы связи Calltouch аккаунтов с сайтами
		getCalltouchAccountsSitesMeta: builder.query<
			{ statuses: string[]; columns: string[] },
			void
		>({
			query: () => API_ENDPOINTS.CALLTOUCH_ACCOUNTS_SITES.META,
		}),

		// Получение списка связей Calltouch аккаунтов с сайтами с фильтрацией, сортировкой и пагинацией
		getCalltouchAccountsSites: builder.query<
			{ sites: CalltouchAccountSite[]; total: number; page: number; limit: number },
			Partial<CalltouchAccountSiteFilters>
		>({
			query: (filters) => {
				const filterRules = {
					title: (
						filters: Partial<CalltouchAccountSiteFilters>,
						params: URLSearchParams,
					) => {
						if (filters.title) params.append('title', filters.title);
					},
					ct_account_id: (
						filters: Partial<CalltouchAccountSiteFilters>,
						params: URLSearchParams,
					) => {
						if (filters.ct_account_id)
							params.append('ct_account_id', filters.ct_account_id.toString());
					},
					ct_site_ids: (
						filters: Partial<CalltouchAccountSiteFilters>,
						params: URLSearchParams,
					) => {
						if (filters.ct_site_ids)
							params.append('ct_site_id', filters.ct_site_ids.toString());
					},
					page: (filters: Partial<Filters>, params: URLSearchParams) => {
						if (filters.page) params.append('page', filters.page.toString());
					},
					limit: (
						filters: Partial<CalltouchAccountSiteFilters>,
						params: URLSearchParams,
					) => {
						if (filters.limit) params.append('limit', filters.limit.toString());
					},
					sort: (
						filters: Partial<CalltouchAccountSiteFilters>,
						params: URLSearchParams,
					) => {
						if (filters.sortKey && filters.sortOrder) {
							const sortParam =
								filters.sortOrder === 'descend'
									? `-${filters.sortKey}`
									: filters.sortKey;
							params.append('sort', String(sortParam));
						}
					},
				};

				const queryParams = buildQueryParams(filters, filterRules);

				return {
					url: `${API_ENDPOINTS.CALLTOUCH_ACCOUNTS_SITES.LIST}${queryParams}`,
				};
			},
			providesTags: (result) =>
				result?.sites
					? [
							...result.sites.map(({ ct_account_id }: CalltouchAccountSite) => ({
								type: 'CalltouchAccountsSites' as const,
								ct_account_id,
							})),
							{ type: 'CalltouchAccountsSites', id: 'LIST' },
						]
					: [{ type: 'CalltouchAccountsSites', id: 'LIST' }],
		}),

		// Получение данных связи Calltouch аккаунта с сайтами по ID
		getCalltouchAccountSiteById: builder.query<CalltouchAccountSite, { ct_account_id: number }>(
			{
				query: ({ ct_account_id }) =>
					API_ENDPOINTS.CALLTOUCH_ACCOUNTS_SITES.BY_ID(ct_account_id),
				providesTags: (_result, _error, { ct_account_id }) => [
					{ type: 'CalltouchAccountsSites', ct_account_id },
				],
			},
		),

		// Создание новой связи для Calltouch аккаунта и сайтов
		createCalltouchAccountSite: builder.mutation<
			CalltouchAccountSite,
			Partial<CalltouchAccountSite>
		>({
			query: (body) => {
				const includeFields = ['title', 'ct_account_id', 'ct_site_ids'];
				const selectedBody = selectFields(body, includeFields);

				const { ct_site_ids, ...serializedBody } = selectedBody;

				if (ct_site_ids) {
					serializedBody.ct_site_ids = processField(ct_site_ids);
				}

				if (serializedBody.ct_account_id) {
					serializedBody.ct_account_id = Number(serializedBody.ct_account_id);
				}

				return {
					url: API_ENDPOINTS.CALLTOUCH_ACCOUNTS_SITES.CREATE,
					method: 'POST',
					body: serializedBody,
				};
			},
			invalidatesTags: [{ type: 'CalltouchAccountsSites', id: 'LIST' }],
		}),

		// Обновление данных связи для Calltouch аккаунта и сайтов
		updateCalltouchAccountSite: builder.mutation<
			CalltouchAccountSite,
			{ ct_account_id: number; title: string; status: number }
		>({
			query: (body) => {
				const includeFields = ['title', 'id', 'ct_site_ids'];
				const selectedBody = selectFields(body, includeFields);

				const { id, ...serializedBody } = selectedBody;

				if (serializedBody.ct_site_ids) {
					serializedBody.ct_site_ids = processField(serializedBody.ct_site_ids);
				}
				const includeFieldsDouble = ['title', 'ct_account_id', 'ct_site_ids'];
				const selectedBodyDouble = selectFields(serializedBody, includeFieldsDouble);

				return {
					url: API_ENDPOINTS.CALLTOUCH_ACCOUNTS_SITES.UPDATE(id),
					method: 'PUT',
					body: selectedBodyDouble,
				};
			},
			invalidatesTags: (_result, _error, { ct_account_id }) => [
				{ type: 'CalltouchAccountsSites', id: ct_account_id },
				{ type: 'CalltouchAccountsSites', id: 'LIST' },
			],
		}),

		// Удаление связи для Calltouch аккаунта и сайтов
		deleteCalltouchAccountSite: builder.mutation<void, { id: number }>({
			query: (args) => {
				const { id } = args;
				const url = API_ENDPOINTS.CALLTOUCH_ACCOUNTS_SITES.DELETE(id); // Теперь 'id' будет доступен из объекта

				return {
					url,
					method: 'DELETE',
				};
			},
			invalidatesTags: (_result, _error, args) => {
				const { id } = args;

				return [
					{ type: 'CalltouchAccountsSites', id: id },
					{ type: 'CalltouchAccountsSites', id: 'LIST' },
				];
			},
		}),
	}),
});

export const {
	useGetCalltouchAccountsSitesMetaQuery,
	useGetCalltouchAccountsSitesQuery,
	useGetCalltouchAccountSiteByIdQuery,
	useCreateCalltouchAccountSiteMutation,
	useUpdateCalltouchAccountSiteMutation,
	useDeleteCalltouchAccountSiteMutation,
} = calltouchAccountsSitesApi;
