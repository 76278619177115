const createEndpoint = (entity: string, accountId: string | number, id?: string | number) => {
	return id
		? `admin/v1/account/${accountId}/${entity}/${id}`
		: `admin/v1/account/${accountId}/${entity}`;
};

const createProjectSubEntityEndpoint = (
	accountId: string | number,
	projectId: string | number,
	entity: string,
	subEntityId?: string | number,
) => {
	return subEntityId
		? `admin/v1/account/${accountId}/project/${projectId}/${entity}/${subEntityId}`
		: `admin/v1/account/${accountId}/project/${projectId}/${entity}`;
};

export const API_ENDPOINTS = {
	AUTH: {
		LOGIN: 'admin/v1/auth/login',
		LOGOUT: 'admin/v1/auth/logout',
		REFRESH_TOKEN: 'admin/v1/auth/refresh-token',
		RESET_PASSWORD_REQUEST: 'admin/v1/auth/password-reset-request',
		RESET_PASSWORD: 'admin/v1/auth/reset-password',
	},
	USER: {
		INFO: 'admin/v1/user/info',
		LIST: 'admin/v1/user',
		CREATE: 'admin/v1/user',
		META: 'admin/v1/user/meta',
		BY_ID: (id: string | number) => `admin/v1/user/${id}`,
		UPDATE: (id: string | number) => `admin/v1/user/${id}`,
		DELETE: (id: string | number) => `admin/v1/user/${id}`,
		CHANGE_PASSWORD: (id: string | number) => `admin/v1/user/${id}/change-password`,
	},
	USER_ACCOUNT_PERMISSION: {
		META: 'admin/v1/user-account-permission/meta',
		LIST: (user_id: string | number) => `admin/v1/user/${user_id}/account-permission`,
		CREATE: (user_id: string | number) => `admin/v1/user/${user_id}/account-permission`,
		BY_ID: (user_id: string | number, id: string | number) =>
			`admin/v1/user/${user_id}/account-permission/${id}`,
		UPDATE: (user_id: string | number, id: string | number) =>
			`admin/v1/user/${user_id}/account-permission/${id}`,
		DELETE: (user_id: string | number, id: string | number) =>
			`admin/v1/user/${user_id}/account-permission/${id}`,
	},
	ACCOUNT: {
		LIST: 'admin/v1/account',
		CREATE: 'admin/v1/account',
		META: 'admin/v1/account/meta',
		BY_ID: (id: string | number) => `admin/v1/account/${id}`,
		UPDATE: (id: string | number) => `admin/v1/account/${id}`,
		DELETE: (id: string | number) => `admin/v1/account/${id}`,
	},
	PROJECT: {
		LIST: (account_id: string | number) => createEndpoint('project', account_id),
		CREATE: (account_id: string | number) => createEndpoint('project', account_id),
		META: 'admin/v1/project/meta',
		BY_ID: (account_id: string | number, id: string | number) =>
			createEndpoint('project', account_id, id),
		UPDATE: (account_id: string | number, id: string | number) =>
			createEndpoint('project', account_id, id),
		DELETE: (account_id: string | number, id: string | number) =>
			createEndpoint('project', account_id, id),

		// Эндпоинты для интеграций в проекте
		ADD_INTEGRATION: (account_id: string | number, project_id: string | number) =>
			createProjectSubEntityEndpoint(account_id, project_id, 'integration'),
		UPDATE_INTEGRATION: (
			account_id: string | number,
			project_id: string | number,
			integration_id: string | number,
		) => createProjectSubEntityEndpoint(account_id, project_id, 'integration', integration_id),
		DELETE_INTEGRATION: (
			account_id: string | number,
			project_id: string | number,
			integration_id: string | number,
		) => createProjectSubEntityEndpoint(account_id, project_id, 'integration', integration_id),

		// Эндпоинты для промптов в проекте
		ADD_AI_PROMPT: (account_id: string | number, project_id: string | number) =>
			createProjectSubEntityEndpoint(account_id, project_id, 'ai-prompt'),
		UPDATE_AI_PROMPT: (
			account_id: string | number,
			project_id: string | number,
			ai_prompt_id: string | number,
		) => createProjectSubEntityEndpoint(account_id, project_id, 'ai-prompt', ai_prompt_id),
		DELETE_AI_PROMPT: (
			account_id: string | number,
			project_id: string | number,
			ai_prompt_id: string | number,
		) => createProjectSubEntityEndpoint(account_id, project_id, 'ai-prompt', ai_prompt_id),
	},
	INTEGRATION: {
		META: 'admin/v1/integration/meta',
		LIST: (account_id: string | number) => createEndpoint('integration', account_id),
		CREATE: (account_id: string | number) => createEndpoint('integration', account_id),
		BY_ID: (account_id: string | number, id: string | number) =>
			createEndpoint('integration', account_id, id),
		UPDATE: (account_id: string | number, id: string | number) =>
			createEndpoint('integration', account_id, id),
		DELETE: (account_id: string | number, id: string | number) =>
			createEndpoint('integration', account_id, id),
	},
	AI_PROMPT: {
		META: 'admin/v1/ai-prompt/meta',
		LIST: (account_id: string | number) => createEndpoint('ai-prompt', account_id),
		CREATE: (account_id: string | number) => createEndpoint('ai-prompt', account_id),
		BY_ID: (account_id: string | number, id: string | number) =>
			createEndpoint('ai-prompt', account_id, id),
		UPDATE: (account_id: string | number, id: string | number) =>
			createEndpoint('ai-prompt', account_id, id),
		DELETE: (account_id: string | number, id: string | number) =>
			createEndpoint('ai-prompt', account_id, id),
	},
	TRIGGER: {
		META: 'admin/v1/trigger/meta',
		LIST: (account_id: string | number) => createEndpoint('trigger', account_id),
		CREATE: (account_id: string | number) => createEndpoint('trigger', account_id),
		BY_ID: (account_id: string | number, id: string | number) =>
			createEndpoint('trigger', account_id, id),
		UPDATE: (account_id: string | number, id: string | number) =>
			createEndpoint('trigger', account_id, id),
		DELETE: (account_id: string | number, id: string | number) =>
			createEndpoint('trigger', account_id, id),
	},
	CALLTOUCH_ACCOUNTS_SITES: {
		META: 'admin/v1/calltouch-accounts-sites/meta',
		LIST: 'admin/v1/calltouch-accounts-sites',
		CREATE: 'admin/v1/calltouch-accounts-sites',
		BY_ID: (ctAccountId: string | number) => `admin/v1/calltouch-accounts-sites/${ctAccountId}`,
		UPDATE: (ctAccountId: string | number) =>
			`admin/v1/calltouch-accounts-sites/${ctAccountId}`,
		DELETE: (ctAccountId: string | number) =>
			`admin/v1/calltouch-accounts-sites/${ctAccountId}`,
	},
	GLOBAL_VARIABLE: {
		META: 'admin/v1/global-variable/meta',
		LIST: 'admin/v1/global-variable',
		CREATE: 'admin/v1/global-variable',
		GET_BY_KEY: (key: string | number) => `admin/v1/global-variable/${key}`,
		UPDATE: (key: string | number) => `admin/v1/global-variable/${key}`,
		DELETE: (key: string | number) => `admin/v1/global-variable/${key}`,
	},
};
