import { useCallback, useEffect } from 'react';
import { useGetUserQuery } from '@/modules/auth/api/authApi';
import { useAppDispatch, useAppSelector } from '@/app/store/hooks';
import { setUser } from '@/modules/auth/store/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { isPrivateRoute } from '@/shared/utils/routesUtils';
import { ROUTES } from '@/shared/constants/routes';
import useAuthState from '@/modules/accounts/hooks/UseAuthState';

interface UseAuthInitializationReturn {
	loading: boolean;
	loadingMessage: string;
}

const useAuthInitialization = (): UseAuthInitializationReturn => {
	const { user, token } = useAuthState();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const loading = useAppSelector((state) => state.loading.loading);
	const loadingMessage = useAppSelector((state) => state.loading.loadingMessage);

	// Проверяем, является ли текущий маршрут приватным
	const isOnPrivateRoute = isPrivateRoute(location.pathname);

	// Получаем данные пользователя, если есть токен и маршрут приватный
	const { data: userData } = useGetUserQuery(undefined, {
		skip: !token || !isOnPrivateRoute,
		refetchOnMountOrArgChange: true,
	});

	// Функция для перенаправления
	const redirectTo = useCallback(
		(path: string) => {
			navigate(path, { replace: true });
		},
		[navigate],
	);

	// Определяем предыдущий маршрут для перенаправления
	const from = location.state?.from?.pathname;

	useEffect(() => {
		// Если нет токена, перенаправляем на логин
		if (!token && isOnPrivateRoute) {
			redirectTo(ROUTES.LOGIN);
			return;
		}

		// Если данные пользователя получены и токен валидный
		if (userData) {
			dispatch(setUser(userData));
			if (!user?.id) {
				// Перенаправляем на нужный маршрут
				if (from) {
					redirectTo(from === ROUTES.LOGIN ? ROUTES.WELCOME : from);
				}
			}
		}
	}, [token, userData, dispatch, user, from, isOnPrivateRoute, redirectTo]);

	return { loading, loadingMessage };
};

export default useAuthInitialization;
